#badheaderheading{
    margin: 5px;
    padding: 5px;
}
.validationbox{
    margin-left: 15px;
}
.filenamewrap{
    margin-left: -15px;
}
.icondiv{
    margin-left: -30px;
}
.ulchild{
    margin-left: 10px;
}
