*{
    padding: 0px;
    margin: 0px;
}
.padding-right-10{
    padding-right: 10px;
}
.test-icon-font{
    font-size: 12px;
    font-weight: bold;
}
.margin-0-10 {
    margin: 0 10px;
}
.form-padding-0{
    padding: 0;
}
.inputs-grid {
    min-height: 650px;
}
@media screen and (max-width: 980px) {
    .inputs-grid {
        min-height: 470px;
    }
}