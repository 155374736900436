
html {
    scroll-behavior: smooth;
    }
    
    .grid__posters {
    width: auto;
    grid-gap: 1.5rem 1rem;
    flex-wrap: wrap;
    justify-content: flex-start;
    }
    /*TODO: this doesn't seem very responsive friendly */
    .Loader-heading{
        padding: 50px;
    }
    .em-select-padding{
        padding-left: 0px !important;
    }
    @media(max-width:480px){
        .grid__posters{
            width:100%;
            grid-gap: 0.5rem 1rem;
        }
    }
    .list-divrelease{
        margin-left: 30px;
    }
    .ullistrelease{
        margin: 5px;
    }
    .bugrel-fix{
        margin-top: 30px;
        margin-left: 15px;
    }
    