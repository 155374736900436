.EmulatorSidebar-navlink{
  display: flex;
  flex-direction: row;
  height: 50px;
  align-items: center;
  padding: 5px 5px 5px 10px;
  width: 100%;
}

.display-none{
  display: none;
}

.width-50{
  width: 50px !important;
}

.navlink-child{
  margin-left: 20px;
  min-width: 56px !important;
}

.EmulatorSidebar-navlink:hover {
  text-decoration: none;
  background-color:#F3F4F6;
}
.heading {
  padding: 8px 16px;
}
.active_menu_link {
   background: rgba(57,194,215, 0.1);
}
@media only screen and (max-width: 978px) {
  .EmulatorSidebar-navlink{
    padding: 5px 15px;
  }
}