.mainG-heading {
    margin-bottom: 10px;
    margin-right: 10px;
}
.singlefileupload-grid {
    border: 1px solid grey;
    padding: 5px;
    margin-bottom: 2px;
}

#btnvalidate {
    margin: 5px;
}
#btnvalidatewrap {
    display: flex;
    margin-left: auto;
}
.tabulator .tabulator-footer .tabulator-page.active {
    border-color: #39C2D7 !important;
    background-color: #39C2D7 !important;
    color: #fff !important;
}

.tabulator .tabulator-tableholder {
    position: relative;
    width: 100%;
    height: 100% !important;
    -webkit-overflow-scrolling: touch;
}

.upload .MuiGrid-container {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
}
#file-mang-upload .MuiDialog-paperWidthSm {
    min-width: 500px;
    max-width: 600px;
}
#headerheading{
    display: flex;
}
.ulchilddata{
    margin-left: 25px;
}