/*! CSS Used from: Embedded */
*, *::before, *::after {
  box-sizing: border-box;
}

.ExploreNav-grid{
  display: flex;
  flex-direction: row !important;
  width: 50% !important;
  justify-content: right !important;
}
.ExploreNav-checkbox{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.explore-title{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.main-heading-emu{
  text-decoration: none;
}
.main-heading-emu:hover {
  text-decoration: none;
}
.search-filter .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
  min-height: 20px !important;
  max-height: 30px !important;
}
.search-filter .MuiAutocomplete-hasPopupIcon.css-1h51icj-MuiAutocomplete-root .MuiFilledInput-root, .MuiAutocomplete-hasClearIcon.css-1h51icj-MuiAutocomplete-root .MuiFilledInput-root {
  padding-top: 5px !important;
}
.search-filter .MuiPaper-elevation1{
  box-shadow: none !important;
}
ul {
  margin-top: 0;
  margin-bottom: 1rem;
}
svg {
  overflow: hidden;
  vertical-align: middle;
}

button {
  border-radius: 0;
}

input, button {
  margin: 0;
}

button, input {
  overflow: visible;
}

button {
  text-transform: none;
}

button::-moz-focus-inner, [type=button]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

[type=search] {
  outline-offset: -2px;
}
.nav {
  display: flex;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

button, html [type=button] {
  cursor: pointer;
}

a {
  color: #1068bf;
}

svg {
  vertical-align: baseline;
}
.dropdown {
  position: relative;
}
.explore-topnavbar{
  z-index: 10;
  padding-left:32px;
  padding-right:32px;
  padding-top: 24px;
  padding-bottom: 5px !important;
  position:fixed;
  left: 0;
  right: 0;
}
.explore-allcards{
  padding: 5px;
  margin-top: 140px;
}

@media((min-width:541px) and (max-width:768px)){
  .explore-allcards{
    margin-top: 215px;
  }
}
@media((min-width:769px) and (max-width:799px)){
  .explore-allcards{
    margin-top: 300px;
  }
}
@media((min-width:800px) and (max-width:1280px)){
  .explore-allcards{
    margin-top: 190px;
  }
}
@media((min-height:270px) and (max-height:550px)){
  .explore-topnavbar{
    position: relative;
    padding: 5px;
    margin: 0;
  }
  .explore-allcards{
    margin-top: 0px;
  }
}
input {
  border-radius: 0.25rem;
  color: #303030;
  background-color: #fff;
}

input::-moz-placeholder {
  color: #868686;
  opacity: 1;
}

input::-ms-input-placeholder {
  color: #868686;
}

input:-ms-input-placeholder {
  color: #868686;
}

svg.s16 {
  width: 16px;
  height: 16px;
}

svg.s16 {
  vertical-align: -3px;
}

.gl-display-flex {
  display: flex;
}

.gl-flex-grow-1 {
  flex-grow: 1;
}

/*! CSS Used from: https://navContent.com/assets/application-a3b1c039a7905382379baf923bc3613a8560b385400c11793e9c81ebc0c2c2ec.css ; media=all */
@media all {
  *, *::before, *::after {
    box-sizing: border-box;
  }
  ul {
    margin-top: 0;
    margin-bottom: 1rem;
  }
  a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
  }
  a:hover {
    color: #0056b3;
    text-decoration: underline;
  }
  svg {
    overflow: hidden;
    vertical-align: middle;
  }
  button {
    border-radius: 0;
  }

  input, button {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }
  button, input {
    overflow: visible;
  }
  button {
    text-transform: none;
  }
  button, [type=button] {
    -webkit-appearance: button;
  }
  button::-moz-focus-inner, [type=button]::-moz-focus-inner {
    padding: 0;
    border-style: none;
  }
  [type=search] {
    outline-offset: -2px;
    -webkit-appearance: none;
  }
}

@media all {
  .dropdown {
    position: relative;
  }
}

@media print {
  *, *::before, *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
}

@media all {
  .gl-tabs-nav {
    border-style: solid;
    border-color: #dbdbdb;
    border-width: 0;
    border-bottom-width: 1px;
  }
}
@media all {
  .gl-tab-nav-item {
    color: #303030;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    line-height: 1rem;
    font-size: 0.875rem;
    display: flex;
    justify-content: center;
    overflow: hidden;
    transition: box-shadow 100ms linear;
  }
}
@media all {
  .gl-tab-nav-item:hover {
    color: #303030;
  }
}
@media all {
  .gl-tab-nav-item:hover:not(.gl-tab-nav-item-active) {
    box-shadow: inset 0 -2px 0 0 #bfbfbf;
  }
}
@media all {
  .gl-tab-nav-item-active {
    font-weight: 600;
    color: #303030;
    box-shadow: inset 0 -2px 0 0 var(--gl-theme-accent, #39C2D7);
  }
}

@media all {
  button, html [type=button] {
    -webkit-appearance: inherit;
    cursor: pointer;
  }
}
@media all {
  a {
    color: #1068bf;
  }
}
@media all {
  svg {
    vertical-align: baseline;
  }
}
@media all {
  a {
    transition: background-color 100ms linear, color 100ms linear, border 100ms linear;
  }
}
@media all {
  .dropdown {
    position: relative;
  }
}
@media (max-width: 768px) {
  .ExploreNav-grid{
    width: 100% !important;
    display: flex;
    flex-direction: row !important;
  }
  .nav-controls{
    display: flex !important;
    flex-direction: row !important;
  }
}
@media (max-width: 650px) {
  .nav-item{
    min-width: 200px;
  }
}

@media (max-width: 375px){
  .gl-tab-nav-item{
    font-size: 0.75rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}

@media all {
  input {
    border-radius: 0.25rem;
    color: #303030;
    background-color: #fff;
  }
}

@media all {
  .top-area {
    border-bottom: 1px solid #dbdbdb;
    display: flex;
    align-items: center;
    /* flex-direction: row;
    justify-content: space-between; */
  }
}

@media (max-width: 500px){
  .gl-tabs-nav{
    margin: 10px 0;
  }
}
@media all {
  .top-area .nav-controls {
    display: inline-block;
    text-align: right;
  }
}
@media all {
  .top-area .nav-controls > .dropdown, .top-area .nav-controls > form {
    margin-right: 8px;
  }
}
@media all {
  .top-area .nav-controls > .dropdown:last-child {
    margin-right: 0;
    float: right;
  }
}
@media all {
  .top-area .nav-controls input {
    display: inline-block;
    position: relative;
  }
}
@media (min-width: 768px) {
  .top-area .nav-controls {
    display: flex;
    align-items: center;
  }
}
@media (max-width: 991.98px) {
  .top-area .nav-controls {
    flex: 0 0 100%;
    margin-top: 8px;
  }
}
@media (max-width: 767.98px) {
  .top-area .nav-controls {
    padding-bottom: 0;
    width: 100%;
  }
  .top-area .nav-controls form, .top-area .nav-controls .dropdown, .top-area .nav-controls , .top-area .nav-controls {
    margin: 0 0 8px;
    display: block;
    width: 100%;
  }
  .top-area .nav-controls {
    margin-bottom: 0;
  }
  .top-area .nav-controls form {
    display: block;
    height: auto;
    margin-bottom: 8px;
  }
  .top-area .nav-controls form input {
    width: 100%;
    margin: 0 0 10px;
  }
}
@media all {
  input::-webkit-input-placeholder {
    color: #868686;
  }
}
@media all {
  input::-moz-placeholder {
    color: #868686;
    opacity: 1;
  }
}
@media all {
  input::-ms-input-placeholder {
    color: #868686;
  }
}
@media all {
  input:-ms-input-placeholder {
    color: #868686;
  }
}
/* @media all {
  svg {
    fill: currentColor;
  }
} */
@media all {
  svg.s16 {
    width: 16px;
    height: 16px;
  }
}
@media all {
  svg.s16 {
    vertical-align: -3px;
  }
}
@media all {
  input[type=search] {
    -webkit-appearance: textfield;
  }
}
@media print {
  .nav {
    display: none !important;
  }
  a[href]::after {
    content: none !important;
  }
}
/*! CSS Used from: https://navContent.com/assets/application_utilities-786d136b044fbe962900ea2ea4d3121385103fc29d7375095c801d32134e71af.css ; media=all */
@media all {
  .gl-border-none {
    border-style: none;
  }
  .gl-display-flex {
    display: flex;
  }
  .gl-flex-grow-1 {
    flex-grow: 1;
  }
}
.padding_1rem {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.addEmulatorbtnstyle{
  width: 100%;
  height: 100%;
}
.MuiContainer-root{
  margin: 0 !important;
}
.actions-delete{
  color: white !important;
}
.outputsynopsis{
  width: 100px;
  margin-bottom: 5px;
}
#statusicons{
  color: rgba(57, 194, 215, 0.7);
}
.em-display-grid{
  display: grid;
}
.scatter-matrixdiv{
  max-height: 80%;
  grid-gap: 1rem;
}