
.ml-display-flex{
    display: flex;
}
.ml-p-5{
    padding: 5px;
}
.ml-flex-dir-row{
    flex-direction: row;
}
.ml-flex-dir-col{
    flex-direction: column;
}
.ml-gap-1 {
    gap: 8px;
}
.ml-space-between{
    justify-content: space-between;
}
.ml-justify-center{
    justify-content: center;
}
.ml-justify-end{
    justify-content: end;
}
.ml-justify-start{
    justify-content: start;
}
.ml-space-around{
    justify-content: space-around;
}
.ml-align-center{
    align-items: center;
}
.ml-align-start {
    align-items: flex-start;
}
.ml-text-center{
    text-align: center;
}
.ml-align-end{
    align-items: end;
}
.ml-p-top-10{
    padding-top: 10px;
}
.margin-top-20{
    margin-top: 20px !important;
}
.margin-to-10{
    margin-top: 10px;
}
.margin-left-20{
    margin-left: 20px;
}
.padding-top-5 {
    padding-top: 5px;
}
.margin-bottom-5{
    margin-bottom: 5px;
}
.margin-right-10{
    margin-right: 10px !important;
}

.ml-float-right{
    float: right;
}
.ml-height{
    height: 100%;
}
.ed-small {
    font-style: italic;
    display: inline-block;
    min-width: fit-content;
    color: #C0C0C0;
}
 /* .h1-1 {
    font-size: x-large;
    font-weight: 700;
} */
.ml-status{
    border-radius: 10px;
}
.ml-failure-chip{
    position: relative;
    height: 1em;
    top: -1em;
    left: 1.5em;
}
.status-done{
    border: 1px solid #008000;
    width: 130px;
}
.status-error{
    border: 1px solid #FF0D19;
    width: 130px;
}
#status-fitting {
    border: 1px solid #39C2D7;
    width: 150px;
    padding: 5px;
}
.status-pending{
    border: 1px solid #808080;
    width: 130px;
}
.status-running{
    border: 1px solid #39C2D7;
    width: 130px;
}
#txt-d {
    margin-bottom: 30px;
}
.p-p1 {
    font-style: italic;
    display: inline-block;
    color: #C0C0C0;
}

.common-section-css{
    border: 1px solid #989595;
    border-radius: 10px;
    padding-top: 2px;
    padding-bottom: 0.4%;
}
 #section-2 {
    min-width: fit-content;
    margin-top: 5px;
}
#section-21 {
    min-width: max-content;
}
.eval-main-grid-container{
    display: grid;
    grid-template-columns: 350px auto;
    grid-template-rows:  auto auto;
}
.expand-button{
    margin-top: -10px;
}
.dv_m_3 {
    margin-left: 20px
}
#eval-p1{
    white-space: nowrap;
    margin-right: .5rem;
    font-size: 1.02rem;
    align-items: center; 
    display: flex;
    padding-bottom: 6px
}
.depth-view{
    margin-top: 236px;
}
.box-view{
    margin-top: 5px;
}
.eval-m-1-2{
    height: 230px;
}
.eval-m-1-3{
    height: 140px;
}

.eval-m-1-4-0{
    border: 1px solid #989595;
    border-radius: 10px;
}
.eval-m2__bottomMargin {
margin-bottom: 100px;
}

.active-radio-input {
    height: 17.1px;
    width: 17.1px;
    margin-top: 0px;
    margin-left: 4px;
    padding: 0px;
}
.newAlignAlgo{
    padding-top: 10px;
}
.newAlignAlgo:hover {
    background-color:#F3F4F6;
    cursor: pointer;
}
.min-width-active-algo{
    min-width: 380px;
}


/* MLCharts CSS */

.MLCharts-main-card{
    height: 550px;
}
.MLCharts-image-container{
    width: 360px;
}
.MLCharts-container{
    margin: 10px;
    width: 950px;
}
.MLCharts-imagelist{
    flex: 1;
    overflow-y: hidden;
    overflow-x: hidden;
}
.MLCharts-imgItem{
    margin: 5px;
    border: 0.5px solid #000000;
    width: 170px !important;
}
.MlCharts-image-selected{
    width: 100%;
    height: 500px;
}
.MLCharts-image-title{
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 10px;
    text-align: center;
    opacity: 0;
    transition: 0.5s;
}
.opacity-on{
    opacity:1
}
.MLCharts-imgItem:hover .MLCharts-image-title{
    opacity: 1;
}
.mlcharts-spinner{
    margin: 5px;
    animation: spin infinite 5s linear;
}
@keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
}



/* MLSideComponent CSS */

.p-legend {
    font-style: italic;
    color: rgb(192, 192, 192);
}
.help-img{
    width: 500px;
}
.img-title-block{
    padding: 0 !important;
}
.img-title-block1{
    padding: 10px 20px !important;
    border-radius: 9999px;
}
.btn-selectiondiv > button{
    margin-top: 5px;
}
.inside-btn-selectiondiv{
    border-radius: 5px !important;
}

/* MLIcons */

.MuiBadge-badge{
    padding: 0px !important;
}
  .iconheight{
     height: 250px !important;
  }
  #inclinedheadings{
    padding: 5px;
    writing-mode: vertical-lr;
    transform: rotate(-180deg);
    text-orientation: mixed;
    white-space: nowrap;
    margin-top: 8px;
  }
.rankbox{
  margin-right: -45px;
}
/* #mui-typo{
    font-size: 15px;
} */
.typogwrap{
    width: 250px;
    margin: 5px;
}
@media screen and (min-width: 600px){
    .line-with-arrows {
        position: relative;
        height: 225px;
        width: 3px;
        border-right: 2px solid grey;
      }
      .labelrank{
        margin-top: 250px;
      }
      .newranklabel{
        margin-top: 35px;
      }
    }
    @media screen and (max-width: 600px){
        .line-with-arrows {
            position: relative;
            height: 290px;
            width: 3px;
            border-right: 2px solid grey;
          }
          .labelrank{
            margin-top: 400px;
          }
          .newranklabel{
            margin-top: 80px;
          }
        }
  .line-with-arrows::before,
  .line-with-arrows::after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
  }
  
  .line-with-arrows::before {
    border-width: 10px 10px 0 10px;
    border-color: grey transparent transparent transparent;
    bottom: -12px;
    left: -7px;
  }
  
  .line-with-arrows::after {
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent grey transparent;
    top: -12px;
    left: -7px;
  }
  .rankheading{
    margin-top: -12px;
  }
  .rankimg{
    margin: 5px;
    width: 200px;
    height: 120px;
  }
.arrowwrap{
    margin-top: 18px;
}
#mlfittingbutton{
    border:2px solid #39C2D7;
    color: #39C2D7;
}
#showmorebtn{
    border: none;
    padding: 2px;
}
.mliconrunning{
    animation: spin 1s linear infinite;
}
#mlrankbadge{
    margin-left: 0px;
    margin-bottom: 3.5px;
}
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}