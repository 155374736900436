
.createEm-label{
    text-align: right;
}
.createEm-close-btn{
    margin: 5px 0 !important;
}
.createEm-input{
    width: 100%;
}
.createEm-grid-item-input{
    width: 77%;
}
.createEm-grid-item{
    width: 23%;
}
.createEm-submit{
    margin: 10px;
}
.createEm-grid-sub{
    margin-bottom:10px ;
}
.createEm-grid-padding-bottom{
    padding-bottom:10px ;
}
.mui-grid{
    padding-top: 1rem;
}
.createEm-grid-main{
    margin-top: 5px !important;
    margin-bottom: 10px !important;
}
.createEm-submit-h4{
    margin: 0 10px;
}
.box-border {
    border: 1px solid #BEBEBE;
    border-radius: 4px;
}
.text-field-container {
    margin-top: 5px;
    overflow: hidden;
  }
  
  .slide-in {
    animation: slideIn 0.5s ease-in-out forwards;
  }
  
  @keyframes slideIn {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }

@media (max-width: 480px) {
    .createEm-grid-main{
        display: flex;
        flex-direction: column;
    }
    .createEm-grid-item-input{
        font-size: 13px;
        width: 100%;
    }
    .createEm-label{
        padding: 0;
        margin: 0;
    }
    .createEm-grid-item{
        width: 50%;
        margin-bottom: 2px !important;
    }
    
}
.cursor-pointer{
    cursor: pointer;
}