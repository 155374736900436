.boldLabel{
    padding: 5px;
}
.miniLabel{
    padding-left: 10px;
}
.blockwidth1{
    width: 178px;
}
.blockwidth2{
     min-width: 136px;
    width: 136px;
    max-width: 136px;
}
.blockwidths{
    line-height: 25px;
}
.childlabel{
    overflow-wrap: anywhere;
}
.variablename{
        padding-left: 0.5rem;
        padding-top: 0.5rem;
        overflow-wrap:anywhere;
}
.blue{
    font-size: 25px;
    line-height: 10px;
}
.btnbox{
    justify-content: space-evenly;
    margin: 0;
    padding: 0;
}
#varheading{
   margin: auto;
   padding-top: 6px;
   display: flex;
   align-items: center;
   justify-content: center;
}
.hoverGrey:hover{
    box-shadow: 1px 1px 5px 1px rgba(128,128,128,0.68);
}
.vdOutput{
    justify-content: right;
    margin-right: 10%;
}
#file-mang .MuiDialog-paperWidthSm {
    max-width: min-content;
}
.titleadd-edit{
    width: auto;
    height: 40px;
}
.vdInput{
    margin-left: 18%;
    justify-content: left;
}
@media screen and (min-width: 349px) and (max-width:450px) {
    .vdInput{
      margin-left: 5%;
    }
    .vdOutput{
        margin-right: auto;
    }
  }
.warningText{
    margin-left: -10%;
    padding: 0;
}
.confirmingText{
    margin-left: -10%;
    padding: 0;
    min-width: 50px;
    max-width: 150px;
    overflow-wrap: anywhere;
}
.inlineTextField{
    display: inline;
}
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    font: inherit;
    letter-spacing: inherit;
    color: currentColor;
    padding: 4px 0 5px;
    border: 0;
    box-sizing: content-box;
    background: none;
    height: 0.4375em;
    margin: 0;
    -webkit-tap-highlight-color: transparent;
    display: block;
    min-width: 0;
    width: 100%;
    -webkit-animation-name: mui-auto-fill-cancel;
    animation-name: mui-auto-fill-cancel;
    -webkit-animation-duration: 10ms;
    animation-duration: 10ms;
    padding: 16.5px 14px;
}
.img-main{
    height: auto;
    max-height: 200px;
    object-fit: scale-down;
    max-width: 100%;
}
.files-reverse{
    flex-direction: column-reverse;
}
.config-name{
    width: -webkit-fill-available;
}
.boxmainwrap{
    line-height: 25px;
}
#stackAlign{
    justify-content: space-between;
    flex-direction: row;
}
.boxwrap{
    width: 170px;
}
.boxwrap1{
    min-width: 150px;
    width: 150px;
    max-width: 150px;
}
.active-width{
    width: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.mui-grids{
    padding-top: 1rem;
    direction:row;
    align-items: center;
    justify-content: space-evenly;
}
.fileconfig{
    display: flex;
    flex-direction: row;
}
.domainicon{
    width: 20px;
    margin-right: 5px;
}
#chipmui{
    margin:2px;
    color: rgba(0, 0, 0, 0.87);
    background-color:rgba(0, 0, 0, 0.10);
    box-sizing:border-box;
    border-color: #fff;
}
.columnlbel-overflow{
    height: 70vh;
    overflow-y: auto;
    overflow-x: hidden;
}
.mismatchlabel{
    font-style: italic;
}
#inputoutputtitle{
    padding-top: 8px;
    line-height: 30px;
}
.drop-nonselect{
    user-select: none;
}
#allbtndirection{
    justify-content: end;
    padding-bottom: 10px;
}
#boxmainwrap{
    height: 33px;
    padding-left: 0px;
}
.create-em-width{
    width: 40px;
}
@media screen  and (max-width: 600px){
    .boldLabel{
        padding: 0px 0px 0px 5px;
    }
    .padding-top{
        padding-top: 2.8px;
    }
}

@media screen and (max-width: 930px) {
    .mconfig-variable-grid{
        display: flex;
        justify-content: center;
    }
}

@media screen and (min-width:430px) and (max-width:541px) {
    .active-width{
        width: 280px;
    }
}
@media screen and (min-width:542px) and (max-width:764px){
    .active-width{
        width: 430px;
    }
}
@media screen and (min-width:765px) and (max-width:835px){
    .active-width{
        width: 500px;
    }
}
@media screen and (min-width:836px) and (max-width:913px){
    .active-width{
        width: 550px;
    }
}
@media screen and (min-width:914px)and (max-width:1250px){
    .active-width{
        width: 350px;
    }
}
@media screen and (min-width:1251px) and (max-width:1365px){
    .active-width{
        width: 580px;
    }
}
@media screen and (min-width:1366px) and (max-width:1500px){
    .active-width{
        width: 280px;
    }
}
@media screen and (min-width:1501px) and (max-width:1800px){
    .active-width{
        width: 400px;
    }
}
@media screen and (min-width:1801px) and (max-width:1900px){
    .active-width{
        width: 500px;
    }
}
@media screen and (min-width:1901px){
    .active-width{
        width: 600px;
    }
}