.emu-row__card{
    position: relative;
    height: 500px;
    min-width: 330px;
    max-width: 330px;
    transition: transform 450ms;
}

.emu-col__card{
    width: 100%;
    height: 80px;
    transition: transform 450ms;
}

.emu-card-icon{
    font-size: 2rem;
}

.emu-card-stack-main{
    display: flex;
    flex-direction: column;
}

.emu-row__card:hover, .emu-col__card:hover {
    transform: scale(1.020);
}

.zoom-hover-10:hover {
    transform: scale(1.10);
}
  .calc-range-hover{
    position: relative;
  }
  .selected-input-div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    align-items: center;
  }

.calc-range-hover:hover .selected-input-div {
    opacity: 1;
}

.emu-card-content-div{
    position: absolute;
    height:130px;
    width: 100%;
    bottom: 0;
    right: 0;
}
.emu-div2{
    position: absolute;
    height:auto;
    width: 100%;
    bottom: 0;
    right: 0;
}
.skeleton-emu-div2{
    position: absolute;
    height:auto;
    width: 100%;
    bottom: 0;
    right: 0;
}
.statusClosebtn-div{
      margin-top: 10px;
      margin-bottom: -10px;
}
.align-vertical{
    font-size: 18px;
    font-weight: 500;
    padding: 5px;
}
.tryit-Inputs{
    background-color: #39C2D7;
}
.tryit-Outputs{
    background-color: #EBCE30;
}
.width-100{
    width: 100%;
}
.justify-end{
    display: flex;
    justify-content: end;
}
.outputscard-result{
    padding: 3px 8px;
}
.my-result-card{
    height: fit-content;
}
.grey-out{
    filter: grayscale(100%);
}
#version-card{
    position: absolute;
    bottom: 0px;
    right: 0px;
}
.margin-right-5{
    margin-right: 5px;
}
.height-max{
   min-height: 200px;
    width: 60px;
    padding: 5px;
}
.calc-toggle-mode{
    flex-direction: row;
}
.results-block,
.result-block {
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 0 5px 5px;
}

.results-block {
  max-height: auto;
}



.animate-card{
    transition: opacity 1s !important;
    opacity: 0 !important;
}
.adminmuigridtable .css-17ixkmj-MuiDataGrid-root .MuiDataGrid-row:hover{
    background-color: white !important;
}
.visible {
    opacity: 1 !important;
}
.hover-styling:hover {
    cursor: pointer;
    transform: scale(1.020);
}
@media(max-width: 480px){
    .my-result-card{
        flex-direction: column !important;
    }
    .emu-card-icon{
        font-size: 1rem;
    }
    .emu-card-stack-main{
        flex-direction: row !important;
    }
    .emu-avatar{
        display: none;
    }
    .skleton-span{
        width: 100%;
    }
    .skeleton-emu-div2{
        display: flex;
        justify-content: start;
        background-color: unset;
    }
    .emu-row__card{
        min-width: 100%;
        max-width: 100%;
        display: flex;
        height: 110px;
        margin: 0.2em 0 !important;
     }
     .emu-card-header{
        height: min-content;
        position: absolute;
        margin-left: 27%;
        width:73%;
        padding-top: 5px !important ;
    }
    .MuiCardHeader-subheader{
        font-size: 10px !important;
    }
    .image_color_filter1{
        max-height:100px;
        max-width: 110px;
        width: 100%;
        margin-top: 5px;
        margin-left: 5px;
        height: fit-content;
    }
    .emu-card-content-col1{
        padding: 0 !important;
        height: fit-content;
    }
    .emu-card-content-stack2{
        height: 40px;
    }
    .emu-card-content-div{
        display: flex;
        width: 68%;
        margin-bottom: 5px;
        height: fit-content;
    }
    .MuiDivider-root{
        margin: 0 !important;
    }
    .grid-child-score2{
        margin: 0 !important;
        height: 40px !important;
    }
    .emu-div2{
        width: fit-content;
        position: relative;
        padding-left: 2px;
        background-color: white;
    }
    .emu-play-button{
        position: absolute !important;
        margin-left: 130px !important;
        margin-bottom: 100% !important;
    }

    .emu-typo{
        font-size: 10px !important;
    }
    .emu-divider{
        margin: 0 2px !important;
    }
    .MuiSvgIcon-root{
        height: 21px !important;
        width: 21px !important;
    }
    .calc-toggle-mode{
        flex-direction: column;
    }
}
@media (max-width: 390px) {
    .emu-row__card{
       height: 90px;
    }
    .emu-card-header{
        font-size: 12px;
    }
    .image_color_filter1{
        max-width: 90px;
        max-height: 90%;
    }
    .emu-card-content-stack2{
        height: 30px;
    }
    .emu-card-content-div{
        width: 72%;
    }
    .grid-child-score2{
        height: 30px !important;
    }
    .MuiSvgIcon-root{
        height: 17px !important;
        width: 17px !important;
    }
}
@media (max-width: 300px) {
    .emu-row__card{
        height: 80px;
     }
}
.selectioncard-chips{
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
   width: 100%;
}
.cards-positions{
    position: relative;
    text-align: center;
}
.tryit-dropdown-grid{
    display: flex;
    flex: 1;
}
.gutter-top{
    padding-top: 0.35em;
}
