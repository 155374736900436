/* Teams.js */

.userform-main-grid {
    padding: 5px;
}

.userform-grid-item {
    width: 100%;
}

.table-col-header {
    font-size: medium !important;
    font-weight: 500 !important;
}

.member-table {
    height: 100vh;
    overflow-y: scroll;
}

.member-manage-title {
    padding: 10px;
}

.member-note {
    white-space: nowrap;
    color: grey;
}

.status-cell {
    width: 170px
}

@media screen and (max-width: 500px) {
    .userform-grid-item {
        font-size: small;
    }

    .action-buttons {
        white-space: nowrap;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .role-cells {
        width: 120px;
    }

    .director-cells {
        display: flex;
        justify-content: end;
        align-items: center;
    }

    .status-cell {
        width: 100px
    }

    .member-cells {
        display: flex;
        flex-direction: column;
    }
}