.para-1{
    margin-top: 15px;
}
.margin-top-50{
    margin-top: 50px;
}
.critheading{
    margin: 15px;
}
#selectcriteria2{
    box-sizing: border-box;
    padding: 5px;
}
.select-critInput{
    width: 60px;
    height: 40px;
    margin-right: 5px;
    margin-left: 5px;
    text-align: center;
    padding: 7px;
    border-radius: 0.25rem;
}
.sc-bound{
    font-size: 10px;
    margin: 10px 20px;
}
.leftheading{
    width: 150px;
}
.maininputdiv{
    width:165px;
}
.rightdiv{
    width: 300px;
}
.rightbox{
    margin: 0;
}