
.gen-tabulator-table{
    margin-top: 30px;
}
.gd-justify-cont-right{
    justify-content: right;
}
.gd-margin-left{
    margin-left: auto;
}
.gd-width{
width: 100px;
}
.row-height-fix {
    height: 30px; /* Set the desired fixed row height */
  }
  .row-height-fix .tabulator-cell {
    height: 30px; 
  }