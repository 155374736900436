* {
  margin: 0;
}
.App-paper{
  min-height: 100vh;
  height: 100%;
}
.margin-auto {
  margin: auto;
}

.height-100{
  height: 150px;
}
.sidebar {
  overflow-y: auto;
  overflow-x: hidden;
  position: fixed;
  background-color: #fff;
  height: calc(100vh - 4rem);
}
.version-position{
  position: absolute;
  bottom: 25px;
  right: 20px;
}
.margin-0-10 .css-74bi4q-MuiDataGrid-overlayWrapper {
  height: 50px !important;
}

main {
  background: #f3f4f6;
  grid-area: main;
}

.main__title {
  display: flex;
  align-items: center;
}
.matrix-grid{
  max-width: 80%;
  min-height: 80%;
  grid-gap: 1rem;
}
.modalwrapp{
  height: 100%;
}

.queuegrid .css-jdaxyo{
  position: static;
}

.queuegrid .css-o4b71y-MuiAccordionSummary-content{
display: flex !important;
flex-direction: row !important;
flex-wrap: wrap !important;
}
.queuegrid .css-o4b71y-MuiAccordionSummary-content.Mui-expanded {
  margin: 0px 0px 0px 0px  !important;
  }
.queuegrid .css-o4b71y-MuiAccordionSummary-content{
    margin: 5px 0px !important;
  }
  .queuegrid .css-15v22id-MuiAccordionDetails-root {
    padding: 0px !important;
}
.object-scale-down{
  object-fit: scale-down;
}

.main__title > img {
  max-height: 100px;
  object-fit: contain;
  margin-right: 20px;
}
.placeholder-image{
  object-fit: cover;
  border-radius: 5px;
   box-shadow: 0.1rem 0.1rem 0.1rem rgb(128,128,128,0.4);
  overflow: hidden;
}
.scatterimgframe{
  min-height: 100%;
  max-width: 100%;
  padding: 10px;
  z-index: 1;
}
.scatterimgbottomframe{
  position: absolute;
  bottom: 3px;
  left: 5px;
  text-transform: uppercase;
  border-top-left-radius: 25px;
  white-space:nowrap;
}
.scatter-title{
  border-bottom: 0.2rem solid rgb(196,196,196,0.8);
}
.image_color_filter1{
  filter: sepia(40%) grayscale(40%) ;
  background-color: rgb(73,79,82,0.5);
  mix-blend-mode: multiply;
  max-height: 320px;
}
.height-width-300{
  width: 300px;
  height: 300px;
}
.copy-to-clipboard-container{
  display: none !important;
}

.height-and-overflow {
  height: calc(100vh - 4rem);
  overflow-y: auto;
}

.chat-bot-open {
  animation: chatBotOpen 0.5s ease-in-out forwards;
}

@keyframes chatBotOpen {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

/* .card {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 70px;
  padding: 25px;
  border-radius: 5px;
  background: #ffffff;
  box-shadow: 5px 5px 13px #ededed, -5px -5px 13px #ffffff;
} */
@media only screen and (max-width: 480px) {
  .emulation-main-avatar{
    height: 100px;
    width: 100px;
  }
  .emulation-user-avatar{
    font-size: 70% !important;
    height: 30px !important;
    width: 30px !important;
  }
}

.charts {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  margin-top: 50px;
}
.heatmap-wrapper{
   width: 55vw;
   height: 80vh;
}
.gradientfeatures{
  width: 30px;
  height: 100%;
  margin-left: 5px;
}
.gradientnumber{
  position: absolute;
  left: 120%;
  transform: translateY(-50%);
  white-space: nowrap;
}
.scatterinputs{
  transform: rotate(-90deg);
}
.scattermatrixresponsive{
  height: 350px;
  width: 800px;
}
@media only screen and(min-width:600px){
  .scattermatrixresponsive{
    height: 800px;
    width: 800px;
  }
}

/* Base Styles */
.scattermatrixresponsiveChart,
.curatedscattermatrixresponsiveChart {
  position: relative;
}

:root {
  --scatter-height: 65vh;
  --curated-height: 65vh;
  --scatter-width: 100vw;
  --curated-width: 85vw;
}

@media only screen and (min-width: 200px) and (max-width: 500px) {
  :root {
    --scatter-height: 65vh;
    --scatter-width: 70vw;
    --curated-width: 80vw;
  }
}

@media only screen and (min-width: 501px) and (max-width: 720px) {
  :root {
    --scatter-height: 30vh;
    --scatter-width: 85vw;
    --curated-width: 90vw;
  }
}

@media only screen and (min-width: 721px) and (max-width: 920px) {
  :root {
    --scatter-height: 30vh;
    --scatter-width: 70vw;
    --curated-width: 90vw;
  }
}

@media only screen and (min-width: 921px) and (max-width: 1024px) {
  :root {
    --scatter-height: 25vh;
    --scatter-width: 40vw;
    --curated-width: 95vw;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1280px) {
  :root {
    --scatter-height: 45vh;
    --scatter-width: 80vw;
    --curated-width: 50vw;
  }
}

@media only screen and (min-width: 1281px) and (max-width: 1600px) {
  :root {
    --scatter-height: 55vh;
    --scatter-width: 48vw;
    --curated-width:55vw;
  }
}

@media only screen and (min-width: 1601px) and (max-width: 1800px) {
  :root {
    --scatter-height: 58vh;
    --scatter-width: 50vw;
    --curated-width: 58vw;
  }
}

@media only screen and (min-width: 1801px) {
  :root {
    --scatter-height: 60vh;
    --scatter-width: 53vw;
    --curated-width: 55vw;
  }
}
.scattermatrixresponsiveChart {
  height: var(--scatter-height) !important;
  width: var(--scatter-width) !important;
}

.curatedscattermatrixresponsiveChart {
  height: var(--curated-height) !important;
  width: var(--curated-width) !important;
}

#accordiontime{
  display: flex;
  flex-direction: row;
}

@media screen and (min-width: 779px) and (max-width:927px) {
  .eventlogcontainer{
    margin-left: 35px;
  }
}
@media screen and (min-width: 350px) and (max-width:400px) {
  #accordsummry{
    margin-left: 0px;
  }
  #accordiontime{
    display: flex;
    flex-direction: row;
    width: 70px;
  }
  #accordicon{
    width: 20px;
    margin-left: 1px;
  }
  #accordsumry{
    width: 200px;
  }
}
@media screen and (min-width: 401px) and (max-width:599px) {
  #accordsummry{
    margin-left: 00px;
  }
}
@media screen and (min-width: 600px) and (max-width:778px) {
  #accordsummry{
    margin-left: 130px;
  }
}
@media screen and (min-width: 779px) and (max-width:927px) {
  #accordsummry{
    margin-left: 190px;
  }
}
@media screen and (min-width: 928px)  {
  #accordsummry{
    margin-left: 220px;
  }
}
.nav-notifications{
  display: flex;
  justify-content: space-between;
}
.eventpagination{
  margin-top: 10px;
  float: right;
}
.randomslicebtn{
  width: 1500px;
  height: 600px;
}
#tryitbtn{
  padding: 8px 16px;
  margin: 5px;
  float: right;
  color: white;
}
#commonbtncss{
  width: 50px;
}
.MuiIconButton-root .summryiconsize{
  font-size: 2rem;
}
.nivofilterwrapper{
  width: 100%;
  height: 300px;
}
.showhidecolumn{
  width: 100%;
  min-height: 50px;
  display: flex;
  justify-content: space-between;
}
.progress-container {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: rgba(57, 194, 215, 0.1);
  overflow: hidden;
  height: 20px;
  position: relative;
}

.progress-bar {
  height: 100%;
  background-color: #39C2D7;
  transition: width 0.5s ease-in-out; 
  display: flex;
  align-items: center;
  justify-content: center;
}

.progress-text {
  position: absolute;
  width: 100%;
  text-align: center;
  font-size: 0.7rem;
  color: #fff;
  pointer-events: none; 
}

.user-message{
  margin-top: 8px;
    padding: 12px 16px;
    min-height: 20px;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    flex-direction: row;
    width: -moz-fit-content;
    width: fit-content;
    font-size: 15px;
    overflow-wrap: anywhere;
    overflow: hidden;
    white-space: pre-wrap;
    margin-right: 6px;
}
.bot-footer{
  margin-top: auto !important;
  max-height: 55px;
  display: flex;
  justify-content: space-between;
  font-size: 12px !important;
  justify-content: center !important;
    padding: 5px !important;
    color: gray !important;
}
.width-75{
  width: 75%;
}