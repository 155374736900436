@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

/* styling scrollbars */

::-webkit-scrollbar {
  width: 5px;
  height: 6px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #a5aaad;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #EDCA4E;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #a5aaad;
}

/* general styling */

* {
  margin: 0;
  padding: 0;
}

body {
  padding: 0px !important;
  margin: 0px !important;
  box-sizing:border-box;
  font-family: sans-serif,Verdana;
}

.MuiAppBar-root{
  padding: 0px !important;
}







