.proj-img-row__card{
    position: inherit;
    height: 155px;
    min-width: 180px;
    max-width: 180px;
    margin: 1em;
    transition: transform 0.3s ease;
}
.proj-img-row__card:hover {
    transform: scale(1.1);
  }
  .proj-img-card-header{
    font-weight: 600;
    margin-left: 10px;
    height: 20px;
    width:80%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-top: 5px !important ;
}
.proj-im-positions{
    text-align: center;
    position: relative;
}
.proj-image_color_filter1{
    object-fit: scale-down;
    max-height:125px;
    max-width: -webkit-fill-available;
    margin: 5px;
    min-height: 125px;
}
.image-viewer{
    margin: 2%;
}
#choose-filebtn{
    display: flex;
    justify-content: center;
    font-size: 16px;
    text-transform: none !important;
}
.img-prevsize-calc{
    min-height: 200px;
    max-height: 200px;
}
.img-prevsize-calc1{
    min-height: 210px;
    max-height: 210px;
}
.img-prevsize-gall{
    min-height: 590px;
    max-height: 590px;
}
.annotate-label{
    padding-left: 15px;
    max-height: 30px;
    min-height: 30px;
}
.annotate-delete{
    padding-left: 5px;
}
.annotate-card{
    padding-left: 15px;
    margin-bottom: 3px;
    border-radius: 50px;
}
.opaque{
    opacity: 0.5;
}
#imgurlbtn{
    color:white;
}
.gallery-dialog{
    min-height: 80vh;
}
.test-dialog {
    max-height: 550px;
    overflow-y: auto;
}

@media screen and (max-width: 480px) {
    .annotate-label{
        min-height: 30px;
        max-height: fit-content;
    }
    .gallery-dialog{
        min-height: 600px;
    }
}