#mui-stepper .css-166ibq-MuiStepIcon-text {
  fill: white !important;
 font-size: 0.6rem;
 font-family: sans-serif,"Helvetica","Roboto","Arial";
}
.accordion-opt .css-o4b71y-MuiAccordionSummary-content.Mui-expanded {
margin: 5px 0px 0px 0px  !important;
}
.width-fifty{
width: 50% !important;
}
.css-17ixkmj-MuiDataGrid-root .MuiDataGrid-cell:focus{
outline: none !important;
}
.capability-table .MuiDataGrid-virtualScrollerContent {
  height: 500px !important;
  overflow-y: auto;
  overflow-x: hidden;
}
.capability-table .MuiDataGrid-virtualScrollerRenderZone {
  height: 500px;
  position: relative;
}

@media only screen and (max-height: 800px)  {
  .capability-table .MuiDataGrid-virtualScrollerContent {
    height: 300px !important; 
  }
  .capability-table .MuiDataGrid-virtualScrollerRenderZone {
    height: 300px; 
  }
}

.css-17ixkmj-MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within, .css-17ixkmj-MuiDataGrid-root .MuiDataGrid-cell:focus-within{
  outline:none!important;
}

.MuiDataGrid-root--densityComfortable{
height: 100px;
}
.MuiDataGrid-cell:hover {
cursor: pointer;
}
.height-100{
height: 100px;
}
.pulse {
 animation: pulse-animation 2s infinite;
}
.pulse2 {
  animation: pulse-animation 5s infinite;
}
@keyframes pulse-animation {
 0% {
   box-shadow: 0 0 0 0px rgba(79, 198, 249, 0.368);
 }
 100% {
   box-shadow: 0 0 0 40px rgba(79, 198, 249, 0);
 }
}

.optimize-btns{
 margin-right: 8px;
}

.circle1{
 position: relative;
 display: flex;
 align-items: center;
 justify-content: center;
}
.css-t89xny-MuiDataGrid-columnHeaderTitle{
  font-weight: bold !important;
}
.accordion-opt .css-o4b71y-MuiAccordionSummary-content {
  margin: 5px 0px 0px 0px !important;
}
.accordion-opt .css-15v22id-MuiAccordionDetails-root{
  padding: 2px !important;
}
#circle-refresh{
 fill:none;
 stroke-width: 10px;
 stroke: url(#GradientColor);
}
.bold-header .MuiDataGrid-colCellTitle {
  font-weight: bold;
}
#svg-refresh{
 top: 0;
 left: 0;
}
.cell-content {
  white-space: nowrap;
  direction: rtl;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}
.cell-content:hover {
  cursor: pointer;
}
.cursor-point{
  cursor: pointer;
}
.custom-header{
  height: 50px;
}